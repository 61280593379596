import { useEffect } from "react";
import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { hydrate } from "react-dom";

Sentry.init({
    dsn: "https://708d27fd6a03891aa1dfd48cec61ab4a@o4506780769124352.ingest.us.sentry.io/4506780772335616",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches
    }), Sentry.replayIntegration()]
})

hydrate(<RemixBrowser />, document);